<template>
  <div class="mb-3 card">
    <div>
      <v-tabs v-model="tab">
        <v-tab key="list">
          {{ $t('accounting.lang_buchhungsaetze') }}
        </v-tab>
        <v-tab key="add">{{ $t('accounting.lang_createAccountingAccount') }}</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="list">
            <Datatable v-show="showTable" ref="PostingRecordsDatatable"
                       :api-endpoint="ENDPOINTS.DATATABLES.ACCOUNTING.POSTINGRECORDS"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       excel-file-name="Posting_Records"
                       @editEntry="entryEdit"
                       @deleteEntry="entryDelete"
                       @deleteEntries="entriesDelete"
                       show-delete-buttons
                       show-edit-buttons
                       :permissionDelete="this.$store.getters['permissions/checkPermission']('accounting_cashBookPostingRecords')"
                       :permissionEdit="this.$store.getters['permissions/checkPermission']('accounting_cashBookPostingRecords')"
            />

            <!-- Table: Click -->
            <div v-if="showUpdate">
              <v-layout>
                <v-flex md5 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="accountNo"
                                :label="$t('generic.lang_AccountNumber')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-flex>
                <v-flex md5 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="accountName"
                                :label="$t('accounting.lang_accountName')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-flex>
                <v-flex md2 mr-2 ml-2>
                  <v-checkbox
                      :hide-details=true
                      v-model="postingAccActive"
                      :label="$t('settings.lang_settings_activ')"
                      autocomplete="off"
                  ></v-checkbox>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex md5 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="costcentre"
                                :label="$t('accounting.costcentre')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex class="text-right">
                  <v-btn text color="error" @click="resetData">{{ $t('generic.lang_prev') }}</v-btn>
                  <v-btn color="error" @click="deleteData">{{ $t('generic.lang_delete') }}</v-btn>
                  <v-btn color="success" :disabled="accountName.length < 1" @click="updateData">
                    {{ $t('generic.lang_edit') }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </v-tab-item>
          <v-tab-item key="add">
            <v-container fluid>
              <v-layout>
                <v-flex md5 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="accountNo"
                                :label="$t('generic.lang_AccountNumber')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-flex>
                <v-flex md5 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="accountName"
                                :label="$t('accounting.lang_accountName')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-flex>
                <v-flex md2 mr-2 ml-2>
                  <v-checkbox
                      :hide-details=true
                      v-model="postingAccActive"
                      :label="$t('settings.lang_settings_activ')"
                      autocomplete="off"
                  ></v-checkbox>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex md5 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="costcentre"
                                :label="$t('accounting.costcentre')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex class="text-right">
                  <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                  <v-btn color="success" :disabled="accountName.length < 1" @click="addData">
                    {{ $t('generic.lang_add') }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<style>
.dataTables_filter {
  display: none;
}
</style>

<script>
import {ENDPOINTS} from '../../config'
import {Events} from "../../plugins/events";
import Datatable from "../datatable/Datatable";
import mixin from "../../mixins/KeyboardMixIns";

export default {
  components: {
    Datatable
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      id: null,
      postingAccActive: 1,
      accountName: "",
      accountNo: "",
      costcentre: "",
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true
        },
        {text: this.$t('generic.lang_AccountNumber'), value: "accountNo"},
        {text: this.$t('accounting.lang_accountName'), value: "accountName"},
        {text: this.$t('settings.lang_settings_activ') + "/" + this.$t('generic.lang_inactive'), value: "active"}
      ],
      excelColumns: [

        {
          label: "ID",
          field: 'id',
        },
        {
          label: this.$t('generic.lang_AccountNumber'),
          field: "accountNo",
        },
        {
          label: this.$t('accounting.lang_accountName'),
          field: "accountName",
        },
        {
          label: this.$t('settings.lang_settings_activ') + "/" + this.$t('generic.lang_inactive'),
          field: "active",
        },
      ],
    }
  },

  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  methods: {
    addData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOKACCOUNTS.CREATE, {
        accountName: self.accountName,
        accountNo: self.accountNo,
        costcentre: self.costcentre,
        radio_accountingRecordActive: self.postingAccActive
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('accounting.lang_buchungssatzErfolgreichAngelegt'),
            color: "success"
          });
          self.$refs.PostingRecordsDatatable.getDataFromApi();
          self.$refs.PostingRecordsDatatable.resetSelectedRows();
          self.depositNameAdd = "";
          self.depositPriceAdd = 0;
          self.showList();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    createNew: function () {
      this.resetData();
      this.showCreate = true;
      this.tab = 1;
      this.showTable = false;
    },
    updateData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOKACCOUNTS.UPDATE, {
        cashBookPostingID: self.id,
        accountName: self.accountName,
        accountNo: self.accountNo,
        costcentre: self.costcentre,
        radio_accountingRecordActive: self.postingAccActive
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('accounting.lang_buchungssatzErfolgreichEdit'),
            color: "success"
          });
          self.resetData();
          self.$refs.PostingRecordsDatatable.getDataFromApi();
          self.$refs.PostingRecordsDatatable.resetSelectedRows();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('accounting.lang_deleteBookingRecordTitle'),
        text: this.$t('accounting.lang_deleteBookingRecordText'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOKACCOUNTS.DELETE, {
            cashBookPostingID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('accounting.lang_buchungssatzErfolgreichDelete'),
                color: "success"
              });

              self.resetData();
              self.$refs.PostingRecordsDatatable.getDataFromApi();
              self.$refs.PostingRecordsDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    entryEdit(entry) {
      let self = this;

      self.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOKACCOUNTS.GET, {
        cashBookPostingID: entry.id
      }).then((res) => {
        if (res.status === 200) {
          self.id = entry.id;
          self.accountName = res.data.formfillData.textFields.accountName;
          self.accountNo = res.data.formfillData.textFields.accountNo;
          self.costcentre = res.data.formfillData.textFields.costcentre;
          self.postingAccActive = res.data.formfillData.additionalFields.radio_accountingRecordActive;
          self.showTable = false;
          self.showUpdate = true;
        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    entryDelete(entry) {
      this.id = entry.id;

      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    },
    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.accountName = "";
      this.accountNo = "";
      this.costcentre = "";
      this.postingAccActive = 1;
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },

  },
}
</script>